import React from 'react'
import './styles.css'
import './stylev2.css'
export default function Sponsor_slider() {
  return (
    
    <div>
      {/* <div className="logo-loop">
        <img
          src="https://assets.website-files.com/636e23ecf118df495e7a2aca/636e699c7d1f02832b5bcf45_Fictional%20company%20logo.svg"
          loading="lazy"
          alt=""
          className="loop-logo-item"
        />
        <img
          src="https://assets.website-files.com/636e23ecf118df495e7a2aca/636e699af2c48f5f3367dc31_Fictional%20company%20logo-1.svg"
          loading="lazy"
          alt=""
          className="loop-logo-item"
        />
        <img
          src="https://assets.website-files.com/636e23ecf118df495e7a2aca/636e699bc0125c10d04b8b89_Fictional%20company%20logo-2.svg"
          loading="lazy"
          alt=""
          className="loop-logo-item"
        />
        <img
          src="https://assets.website-files.com/636e23ecf118df495e7a2aca/636e699aef41c91f17043562_Fictional%20company%20logo-3.svg"
          loading="lazy"
          alt=""
          className="loop-logo-item"
        />
        <img
          src="https://assets.website-files.com/636e23ecf118df495e7a2aca/636e699b4a3e3cfdbf7c1bc1_Fictional%20company%20logo-4.svg"
          loading="lazy"
          alt=""
          className="loop-logo-item"
        />
        <img
          src="https://assets.website-files.com/636e23ecf118df495e7a2aca/636e699a9b355b268a53024d_Fictional%20company%20logo-5.svg"
          loading="lazy"
          alt=""
          className="loop-logo-item"
        />
      </div>
      <div className="logo-loop">
        <img
          src="https://assets.website-files.com/636e23ecf118df495e7a2aca/636e699c7d1f02832b5bcf45_Fictional%20company%20logo.svg"
          loading="lazy"
          alt=""
          className="loop-logo-item"
        />
        <img
          src="https://assets.website-files.com/636e23ecf118df495e7a2aca/636e699af2c48f5f3367dc31_Fictional%20company%20logo-1.svg"
          loading="lazy"
          alt=""
          className="loop-logo-item"
        />
        <img
          src="https://assets.website-files.com/636e23ecf118df495e7a2aca/636e699bc0125c10d04b8b89_Fictional%20company%20logo-2.svg"
          loading="lazy"
          alt=""
          className="loop-logo-item"
        />
        <img
          src="https://assets.website-files.com/636e23ecf118df495e7a2aca/636e699aef41c91f17043562_Fictional%20company%20logo-3.svg"
          loading="lazy"
          alt=""
          className="loop-logo-item"
        />
        <img
          src="https://assets.website-files.com/636e23ecf118df495e7a2aca/636e699b4a3e3cfdbf7c1bc1_Fictional%20company%20logo-4.svg"
          loading="lazy"
          alt=""
          className="loop-logo-item"
        />
        <img
          src="https://assets.website-files.com/636e23ecf118df495e7a2aca/636e699a9b355b268a53024d_Fictional%20company%20logo-5.svg"
          loading="lazy"
          alt=""
          className="loop-logo-item"
        />
      </div> */}
    </div>
  );
}
